import { useCallback, useEffect, useState } from 'react';
import { FormConfig } from '../models/form.model';
import { TranslationService } from '../services/translation.service';

export const useForm = (translationService: TranslationService, formConfig: FormConfig) => {
  const [form, setForm] = useState(formConfig);
  const [translation, setTranslation] = useState(translationService);

  const isInputFieldValid = useCallback(inputField => {
    for(const rule of inputField.validationRules) {
      if(!rule.validate(inputField.value, form)) {
        inputField.errorMessage = rule.message(translation);
        return false;
      }
    }
    
    return true;
  }, [form]);

  const onInputChange = useCallback(event => {
    const { name, value } = event.target;
    const inputObj = { ...form[name] };
    inputObj.value = value;
    const isValidInput = isInputFieldValid(inputObj);

    if(isValidInput && !inputObj.valid) {
      inputObj.valid = true;
    } else if(!isValidInput && inputObj.valid) {
      inputObj.valid = false;
    }
    inputObj.touched = true;
    setForm({ ...form, [name]: inputObj });
  }, [form, isInputFieldValid]);

  const isFormValid = useCallback(() => {
    let isValid = true;
    const array = Object.values(form);

    for(let i=0;i<array.length;i++) {
      if(!array[i].valid) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }, [form]);

  const touchForm = () => {
    let newForm = { ...form };
    for(const name of Object.keys(form)) {
      const inputField = form[name];
      const inputObj = { ...inputField };
      const isValidInput = isInputFieldValid(inputObj);

      if(isValidInput && !inputObj.valid) {
        inputObj.valid = true;
      } else if(!isValidInput && inputObj.valid) {
        inputObj.valid = false;
      }
      inputObj.touched = true;
      newForm = { ...newForm, [name]: inputObj };
    }
    setForm(newForm);
  };

  useEffect(() => {
    let newForm = {...form};
    for(const name of Object.keys(form)) {
      const inputField = form[name];
      const inputObj = { ...inputField };
      inputObj.value = inputField.defaultValue;
      inputObj.valid = isInputFieldValid(inputObj);
      inputObj.touched = false;
      newForm = { ...newForm, [name]: inputObj };
    }
    setForm(newForm);
  }, []);

  const renderInput = (field: string) => {
    const { renderInput, value, valid, touched, errorMessage } = form[field];
    return renderInput(onInputChange, translation, value, valid || !touched, errorMessage, field);
  }

  return {
    renderInput,
    touchForm,
    isFormValid,
    form
  };
}