import { FormError } from '@/src/components/form-error/form-error';
import { useForm } from '@/src/hooks/form.hook';
import { createFormField, FormConfig, requiredValidator } from '@/src/models/form.model';
import { AuthService } from '@/src/services/auth.service';
import { Link, navigate, PageProps } from 'gatsby';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { LargeButton } from '../../components/button/button';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { Subheader } from '../../components/subheader/subheader';
import { TranslationProvider } from '../../contexts/translation.context';
import { TranslationService } from '../../services/translation.service';
import * as styles from './login.module.scss';

const loginForm: FormConfig = {
  email: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="email"
            name="email"
            type="text"
            placeholder={translation.translate('authentication.email')}
            onChange={onInputChange}
            value={value} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  password: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="password"
            name="password"
            type="password"
            placeholder={translation.translate('authentication.password')}
            onChange={onInputChange}
            value={value} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  }
};

export default function LoginPage({ pageContext }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [formError, setFormError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const { renderInput, touchForm, isFormValid, form } = useForm(translation, loginForm);

  const tabs = [
    {
      name: translation.translate('authentication.new_user'),
      href: `/${translation.lang}/signup`
    },
    {
      name: translation.translate('authentication.log_in'),
      href: `/${translation.lang}/login`
    }
  ];

  const login = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormError(false);
    touchForm();
    if(!isFormValid()) {
      return;
    }
    if(!await new AuthService().login(form.email.value, form.password.value)) {
      setFormError(true);
      setFormErrorMessage(translation.translate('authentication.login_failed'));
    } else {
      navigate(`/${translation.lang}/activities`);
    }
  };

  return (
    <>
      <TranslationProvider value={translation}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{translation.translate('helmet.login_title')}</title>
          <meta name="description" content={translation.translate('helmet.login_description')} />
          <meta http-equiv="content-language" content={translation.lang} />
        </Helmet>
        <Header></Header>

        <div className="scrollable no-navbar">
          <div className="content">
            <Subheader.Tabs activeHref={`/${translation.lang}/login`} tabs={tabs}></Subheader.Tabs>

            <h1 className={styles.heading}>
              {translation.translate('authentication.log_in')}
            </h1>

            <div className="app__wrapper">
              <form className="app__form" onSubmit={login}>

                <div className="form__group">
                  <div className="form__field">
                    <label htmlFor="email">{translation.translate('authentication.email')}</label>
                    {renderInput('email')}
                  </div>
                </div>

                <div className="form__group">
                  <div className="form__field">
                    <label htmlFor="password">{translation.translate('authentication.password')}</label>
                    {renderInput('password')}
                  </div>
                </div>

                {formError ?
                  <div className={styles.errorMessage}>
                    {formErrorMessage}
                  </div> : null}

                <div className="form__group">
                  <LargeButton type="submit">
                    <span>{translation.translate('authentication.log_in')}</span>
                  </LargeButton>
                </div>

                <Link to="/" className={styles.forgotPassword}>
                  {translation.translate('authentication.forgot_password')}
                </Link>

              </form>
            </div>
          </div>

          <Footer></Footer>
        </div>
      </TranslationProvider>
    </>
  );
}