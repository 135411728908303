import { Link } from 'gatsby';
import React from 'react';
import * as styles from './subheader.module.scss';

type WrapperProps = {
  children: JSX.Element;
};

type TabsProps = {
  activeHref: string;
  tabs: {
    name: string;
    href: string;
  }[];
};

type StepperProps = {
  firstStep: string;
  secondStep: string;
  activeStep: number;
};

type TextProps = {
  text: string;
};

function Wrapper({ children }: WrapperProps) {
  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  );
}

function Tabs({ tabs, activeHref }: TabsProps) {
  return (
    <div className={styles.tabsContainer}>
      {tabs.map(tab => (
        <Link key={tab.href} className={`${styles.tab} ${tab.href === activeHref ? styles.tabActive : ''}`} to={tab.href}>{tab.name}</Link>
      ))}
    </div>
  );
}

function Stepper({ firstStep, secondStep, activeStep }: StepperProps) {
  return (
    <div className={styles.stepperContainer}>
      <p className={activeStep === 1 ? 'selected' : ''}>1. {firstStep}</p>
      <p className={activeStep === 2 ? 'selected' : ''}>2. {secondStep}</p>
    </div>
  );
}

function Text({ text }: TextProps) {
  return (
    <p>Text</p>
  );
}

export const Subheader = {
  Tabs: (props: TabsProps) => (
    <Wrapper>
      <Tabs {...props}></Tabs>
    </Wrapper>
  ),

  Stepper: (props: StepperProps) => (
    <Wrapper>
      <Stepper {...props}></Stepper>
    </Wrapper>
  ),

  Text: (props: TextProps) => (
    <Wrapper>
      <Text {...props}></Text>
    </Wrapper>
  )
};